import React from 'react'
import linkedinicon from "../../assets/icons/linked-in-icon.png"
import Shreesha from "../../assets/team/Shreesha-Ramdas.png";
import Sharath from "../../assets/team/Sharath-Keshava-Narayana.png"
import Bobby from "../../assets/team/Bobby-Napiltonia.png"
import "./about-advisors.css"


function AboutAdvisors() {
    const TeamAdvisors = [
        {
            name: "Shreesha Ramdas",
            linkedIn: "https://www.linkedin.com/in/shreesharamdas/",
            image: Shreesha,
            role: "Co-Founder Strikedeck",
        },
        {
            name: "Sharath Keshava Narayana",
            linkedIn: "https://www.linkedin.com/in/sharathkishan/ ",
            image: Sharath,
            role: "Co-Founder Observe.AI, Sanas AI",
        },
        {
            name: "Bobby Napiltonia",
            linkedIn: "https://www.linkedin.com/in/bobby-napiltonia-8b82624/",
            image: Bobby,
            role: "ex-CRO Twilio, Salesforce",
        },
    ];

    return (
        <section className="about-team-section">
            <div className="container">
                <h2 className="text-h2-md">Advisors</h2>
                <div className="about-team-members-wrapper">
                    {TeamAdvisors.map((each, i: number) => {
                        return <div className="about-team-item" key={`team-member-${i}`}>
                            <img src={each.image} alt="" className="about-advisor-image" width={250} height={250} placeholder="none" loading="lazy" />
                            <div className="about-team-member-text-wrapper">
                                <h3 className="name">{each.name}</h3>
                                <h3 className="role">{each.role}</h3>
                            </div>
                            {/* <div className={className} /> */}
                            <div style={{ marginTop: "1em", cursor: "pointer", width: "fit-content", marginInline: "auto" }}>
                                <a href={each.linkedIn} target="_blank">
                                    <img src={linkedinicon} alt="linked in logo" width={26} height={26} placeholder="none" loading="lazy" />
                                </a>
                            </div>
                        </div>
                    }
                    )}
                </div>
            </div>
        </section>
    )
}

export default AboutAdvisors