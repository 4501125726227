import React from 'react';
import { graphql } from 'gatsby';

import HtmlHead from '../components/html-head';
import Navbar from '../components/navbar';
import AboutHero from '../components/about-hero';
import AboutSlider from '../components/about-slider';
import AboutSupport from '../components/about-support';
import AboutBacked from '../components/about-backed';
import Footer from '../components/footer';
import { PageData } from '../types';

import '../styles/index.css';
import CtaBlock from '../components/components-group-one/CtaBlock';
import ContactUsFormContainer from '../components/contactus-form-container';
import AboutVoices from '../components/about-voices';
import AboutTeam from '../components/about-team';
import AboutAdvisors from '../components/about-advisors';
import HomeLogos from '../components/home-logos';
import AppFAQAccordion from '../components/AppFaq';


const About: React.FC<PageData> = resp => {
    const seoData = resp.data.allContentfulPageSeoData.edges[0].node;
    const faqData = [
        {
            id: 8,
            question: 'Does AptEdge support voice input?',
            answer: 'AptEdge is currently developing technology to enable this. ',
        },
        {
            id: 10,
            question: 'Does AptEdge support multiple languages?',
            answer: 'Yes, AptEdge’s AnswerGPT™ can create answers in over 100 languages.',
        },
        {
            id: 11,
            question:
                'Is there a limit to the number of questions that users can ask AptEdge?',
            answer:
                'There is no limit. The more AptEdge is used, the better the experience that is delivered each time.',
        },
    ]
    return (
        <>
            <HtmlHead
                title={seoData.title}
                metaDescription={seoData.metaDescription.metaDescription}
                canonicalLink={seoData.canonicalLink}
                openGraphImage={seoData.openGraphImage.file.url}
            />
            <Navbar />
            <AboutHero />
            {/* <AboutSlider /> */}
            <HomeLogos />
            {/* <AboutSupport /> */}
            <AboutTeam />
            <AboutAdvisors />
            <AboutBacked />
            <AboutVoices />
            {/* <ContactUsFormContainer /> */}
            <AppFAQAccordion faqData={faqData} />
            <CtaBlock ctaTitle={undefined} />
            <Footer />
        </>
    );
};

export default About;

export const query = graphql`
    query AboutQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/about" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;