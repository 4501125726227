import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import './about-backed.css';

const AboutBacked: React.FC = () => {
    const getLinkProps = () => {
        return {
            target: '_blank',
            rel: "noreferrer",
            style: {
                display: "flex",
                alignItems: 'center',
                justifyContent: "center",
            }
        }
    }

    return (
        <section className="about-backed-section">
            <div className="container">
                <div className="about-backed-wrapper">
                    <h2 className="text-h2-md">Backed by the best</h2>
                    <div className="image-container">
                        <a href="https://www.unusual.vc/" {...getLinkProps()}>
                            <div
                                className='logo-wrap'
                                style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}
                            >
                                <StaticImage
                                    src="../../assets/about/uvlogo.png"
                                    alt="Unusual Ventures logo"
                                    layout="constrained"
                                    height={85}
                                    quality={90}
                                    loading="eager"
                                    placeholder="none"
                                    className='logo-backed'
                                />
                            </div>
                        </a>
                        <a href="https://www.stage2.capital/" {...getLinkProps()}>
                            <div
                                className='logo-wrap'
                                style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}
                            > <StaticImage
                                    src="../../assets/about/stage-2-capital.png"
                                    alt="stage 2 capital"
                                    layout="constrained"
                                    height={85}
                                    quality={90}
                                    loading="eager"
                                    placeholder="none"
                                    className='logo-backed'
                                />
                            </div>
                        </a>
                        <a href="https://www.ngpartners.com/" {...getLinkProps()}>
                            <div
                                className='logo-wrap'
                                style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}
                            > <StaticImage
                                    src="../../assets/about/nationalgridlogo.png"
                                    alt="national grid logo"
                                    layout="constrained"
                                    height={85}
                                    quality={90}
                                    loading="eager"
                                    placeholder="none"
                                    className='logo-backed'
                                />
                            </div>
                        </a>
                        <a href="https://www.carya.com/" {...getLinkProps()}>
                            <div
                                className='logo-wrap'
                                style={{ display: "flex", alignItems: 'center', justifyContent: "center", }}
                            ><StaticImage
                                    src="../../assets/about/cvp-logo.png"
                                    alt="national grid logo"
                                    layout="constrained"
                                    height={110}
                                    quality={90}
                                    loading="eager"
                                    placeholder="none"
                                    className='logo-backed'
                                />
                            </div>
                        </a>
                        <a href="https://counterpart.com/" {...getLinkProps()}>
                            <div
                                className='logo-wrap'
                                style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}
                            >
                                <StaticImage
                                    src="../../assets/about/counterpartlogo.png"
                                    alt="Counterpart logo"
                                    layout="constrained"
                                    height={85}
                                    quality={90}
                                    loading="eager"
                                    placeholder="none"
                                    className='logo-backed'
                                />
                            </div>
                        </a>
                        <a href="http://www.wolfsongroupinc.com/" {...getLinkProps()}>
                            <div
                                className='logo-wrap'
                                style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}
                            >
                                <StaticImage
                                    src="../../assets/about/wolfson-group.png"
                                    alt="wolfson group logo"
                                    layout="constrained"
                                    height={130}
                                    quality={90}
                                    loading="eager"
                                    placeholder="none"
                                    className='logo-backed'
                                />
                            </div>
                        </a>
                        <a href="https://www.pathbreakervc.com/" {...getLinkProps()}>
                            <div
                                className='logo-wrap'
                                style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}
                            >
                                <StaticImage
                                    src="../../assets/about/pathbreaker.png"
                                    alt="pathbreaker logo"
                                    layout="constrained"
                                    height={110}
                                    quality={90}
                                    loading="eager"
                                    placeholder="none"
                                    className='logo-backed'
                                />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default AboutBacked;
