import React from 'react'
import './app-faq.css'

// @ts-ignore
function AppFAQAccordion({ faqData }) {

    const faqSection = faqData?.map((item: { id: React.Key | null | undefined; question: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; answer: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined }) => {
        return (
            <details
                key={item.id}
                className='faq-accordion-details '
            >
                <summary className={'faq-accordion-question '}>{item.question}</summary>
                <p className={'faq-accordion-answer '}>{item.answer}</p>
            </details>
        )
    })

    return (
        <div className='faq-accordion'>
            <p className="featured-in-title teal ">FAQ</p>
            <div>
                {faqSection}
            </div>

        </div>
    )
}

export default AppFAQAccordion