import React from 'react'
import AppButton from '../../CustomComponents/app-button';
import "./cta-block.css";


function CtaBlock({ ctaTitle }: { ctaTitle?: string }) {
    return (
        <div className='cta-block'>
            <div className='cta-container'>
                {ctaTitle ? <h3 className="cta-heading">{ctaTitle}</h3> : <h3 className="cta-heading">Get Going Today!</h3>} 
                <p className="cta-description">AptEdge is easy to use, works out of the box, and ready to go in minutes.</p>
                <AppButton variant={'variant-teal cta-button'} text={'Schedule Demo'} linkTo={'/get-started'} />
            </div>
        </div>
    )
}

export default CtaBlock;