import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import './about-hero.css';

const aboutHero: React.FC = () => {
    return (
        <section className="hero-section about-hero-section">
            <div className="container">
                <div className="about-hero-wrapper">
                    <h1 className="text-h1 about-h1 animate">The Future of Customer Care is Now AnswerGPT&#8482;</h1>
                    <p className='text-lg-new description animate'>Leveraging the latest in Generative AI technologies, AptEdge has created the industry’s first Answer Engine with AnswerGPT&#8482;.  The root of customer care challenges begins with the inability to quickly get answers to customer questions and problems.  The problem is getting bigger as the amount of knowledge grows and is sprawled in dozens of systems.  AptEdge transforms your domain knowledge and creates dynamic, personalized answers driving down customer care resolution times, increasing CSAT, and improving support team efficiencies.</p>
                </div>
            </div>
        </section>
    );
};

export default aboutHero;
