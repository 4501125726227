import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import linkedinicon from "../../assets/icons/linked-in-icon.png"

import './about-team.css';
import { FluidImageProps } from '../../types';

type TeamMember = {
    node: {
        profilePicture: {
            fluid: FluidImageProps;
        };
        role: string;
        sortOrder: number;
        slug: string;
        name: string;
    };
};

type AboutTeam = {
    allContentfulTeam: {
        edges: TeamMember[];
    };
    allContentfulAdvisors: {
        edges: TeamMember[];
    };
};

const AboutTeam: React.FC = () => {
    let iterator = 0;
    const linkedInlinks: string[] = [
        "https://www.linkedin.com/in/aakritprasad/",
        "https://www.linkedin.com/in/anthonykilman/",
       
    ];

    return (
        <StaticQuery
            query={graphql`
                {
                    allContentfulTeam(sort: { fields: sortOrder, order: ASC }) {
                        edges {
                            node {
                                profilePicture {
                                    fluid(maxWidth: 200, quality: 100) {
                                        ...GatsbyContentfulFluid_withWebp
                                    }
                                }
                                role
                                sortOrder
                                slug
                                name
                            }
                        }
                    }
                }
            `}
            render={(data: AboutTeam) => (
                <section className="about-team-section">
                    <div className="container">
                        <h2 className="text-h2-md">Leaders that listen</h2>
                        <div className="about-team-members-wrapper">
                            {data.allContentfulTeam.edges.filter(each => (each.node.slug === "aakrit-prasad"  || each.node.slug === "anthony-kilman")).map((teamItem: TeamMember, i: number) => {
                                iterator++;
                                if (iterator > 9) {
                                    iterator = 1;
                                }
                                const className = `about-team-member-bg team_bg_${iterator}`;

                                return (<div className="about-team-item" key={`team-member-${i}`}>
                                    <Img
                                        fluid={teamItem.node.profilePicture.fluid}
                                        className="about-team-member-image"
                                        key={`team-member-image-${i}`}
                                        alt={`AptEdge | ${teamItem.node.name}`}
                                        style={{
                                            borderRadius: '50%',
                                            width: '250px',
                                            aspectRatio: '1',
                                        }}
                                    />
                                    <div className="about-team-member-text-wrapper">
                                        <h3 className="name">{teamItem.node.name}</h3>
                                        <h3 className="role">{teamItem.node.role}</h3>
                                    </div>
                                    <div className={className} />
                                    <div style={{ marginTop: "1em", width: "fit-content", marginInline: "auto" }}>
                                        <a href={linkedInlinks[i]} target="_blank">
                                            <img src={linkedinicon} alt="linked in logo" />
                                        </a>
                                    </div>
                                </div>
                                )

                            })}
                        </div>
                    </div>
                </section>
            )}
        />
    );
};

export default AboutTeam;
