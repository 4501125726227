import React from 'react'
import './about-voices.css'

function AboutVoices() {
    return (
        <div className='about-voices-container'>
            <div className='about-voices-card'>
                <div className='wrapper'>
                    <h2 className='text-h2-md' style={{ color: 'var(--text-black)' }}>Amplify customer voices with us</h2>
                    <p className='text-lg-new'>We’re looking for unique individuals to help us channel customer service insights and automate manual processes. We are working on difficult problems using distributed systems, machine learning and leading UX, so send us your resume if you think you’re a good fit.</p>
                    <a href="/careers/" className="btn btn-cta">
                        Careers
                    </a>
                </div>
            </div>
        </div>
    )
}

export default AboutVoices